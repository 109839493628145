<template>

	<div v-if="site" class="w-100 h-100 position-relative overflow-hidden">

		<div class="w-100 h-100 d-flex">

			<div class="d-flex flex-column w-100 h-100">

				<planning_nav :site="modelValue" :workspace="workspace" :is_planning_complete="is_planning_complete" />

				<v-tour name="build-tour" :steps="steps"></v-tour>

				<div class="w-100 h-100 overflow-hidden d-flex">
					<div class="overflow-auto border-end" style="flex: 0 0 270px;">

						<div class="d-flex align-items-center small mb-4 small border-bottom p-4" style="height: 24px;">
							<template v-if="!saving">
								<span class="material-icons-outlined me-2 opacity-50">check</span>
								<span class="opacity-50">Saved</span>
							</template>
							<template v-else>
								<div class="d-flex justify-content-center me-2" style="width: 24px;">
									<div class="spinner-border spinner-border-sm " role="status">
										<span class="visually-hidden">Loading...</span>
									</div>
								</div>
								Saving
							</template>
						</div>

						<!-- <button @click="ai_populate">Populate</button> -->

						<a @click="scroll_to_section" href="#planning-page-1" class="planning-page-link p-4 py-2 mb-0 d-block active">
							1. Front page
						</a>

						<a @click="scroll_to_section" href="#planning-page-2" class="planning-page-link p-4 py-2 mb-0 d-block">
							2. Project details
						</a>

						<a @click="scroll_to_section" href="#planning-page-3" class="planning-page-link p-4 py-2 mb-0 d-block">
							3. Contact details
						</a>

						<a @click="scroll_to_section" href="#planning-page-4" class="planning-page-link p-4 py-2 mb-0 d-block">
							4. Social profiles
						</a>

						<a @click="scroll_to_section" href="#planning-page-5" class="planning-page-link p-4 py-2 mb-0 d-block">
							5. Client history
						</a>

						<a @click="scroll_to_section" href="#planning-page-6" class="planning-page-link p-4 py-2 mb-0 d-block">
							6. Client mission
						</a>

						<a @click="scroll_to_section" href="#planning-page-7" class="planning-page-link p-4 py-2 mb-0 d-block">
							7. Core values
						</a>

						<a @click="scroll_to_section" href="#planning-page-8" class="planning-page-link p-4 py-2 mb-0 d-block">
							8. Target audience
						</a>

						<a @click="scroll_to_section" href="#planning-page-9" class="planning-page-link p-4 py-2 mb-0 d-block">
							8. Key customers
						</a>

						<a @click="scroll_to_section" href="#planning-page-10" class="planning-page-link p-4 py-2 mb-0 d-block">
							10. Core team
						</a>

						<a @click="scroll_to_section" href="#planning-page-11" class="planning-page-link p-4 py-2 mb-0 d-block">
							11. Content considerations
						</a>

						<a @click="scroll_to_section" href="#planning-page-12" class="planning-page-link p-4 py-2 mb-0 d-block">
							12. Service offering
						</a>

						<a @click="scroll_to_section" href="#planning-page-13" class="planning-page-link p-4 py-2 mb-0 d-block">
							13. Unique selling points
						</a>

						<a @click="scroll_to_section" href="#planning-page-14" class="planning-page-link p-4 py-2 mb-0 d-block">
							14. Custom functionality
						</a>

						<a @click="scroll_to_section" href="#planning-page-15" class="planning-page-link p-4 py-2 mb-0 d-block">
							15. Third party plugins
						</a>

					</div>

					<div @scroll="highlight_link" id="planning-pages" class="flex-grow-1 h-100 position-relative py-4 overflow-auto">

						<div class="container h-100">

							<div class="row h-100 justify-content-center mb-4">

								<div class="col-12 col-xxl-10">								

									<form @submit.prevent="submit">										

										<div class="mb-4">

											<div class="">

												<p v-if="success" class="alert alert-success d-flex align-items-center">
													<span class="material-icons-outlined me-2">check_circle</span>
													The {{ tab }} details were updated successfully
												</p>

												<p v-if="error.message" class="alert alert-danger">
													{{ error.message }}
												</p>

												<div id="planning-page-1" class="planning-page" :style="( team && team.button_text) ? 'background: ' + team.button_text + '; color: ' + team.button_bg + ';' : ''">

													<img v-if="team && team.logo" :src="team.logo" class="position-absolute top-50 start-50 translate-middle" style="width: 400%; height: auto; filter: brightness(0) contrast(100%); opacity: 0.025;">

													<div class="planning-page-content position-relative">

														<h1 class="text-start mx-auto text-center" style="font-size: 70px; max-width: 700px;">Creating a new website for {{ site.name }}.</h1>

													</div>

													<div class="planning-page-footer d-flex align-items-center justify-content-between">
														<div>
															<img v-if="team && team.logo" :src="team.logo" height="40" class="d-block">
														</div>
														<p class="planning-page-numbers mb-0">Page 1 of {{ total }}</p>
													</div>

												</div>

												<planning_page step="details" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="2" />

												<planning_page step="contact" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="3" />

												<planning_page step="social" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="4" />

												<planning_page step="history" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="5" />

												<planning_page step="mission" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="6" />

												<planning_page step="values" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="7" :layout="2" />

												<planning_page step="audience" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="8" :layout="2" />

												<planning_page step="clients" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="9" :layout="2" />

												<planning_page step="team" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="10" :layout="2" />

												<planning_page step="content" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="11" />

												<planning_page step="services" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="12" :layout="2" />

												<planning_page step="usps" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="13" :layout="2" />

												<planning_page step="features" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="14" :layout="2" />

												<planning_page step="plugins" :include_description="true" :include_comment="false" v-model="site" v-on:update:modelValue="submit_delay" :team="team" :total="total" :page="15" :layout="2" />

											</div>			

										</div>

									</form>

								</div>

							</div>

						</div>

					</div>
				</div>

			</div>

		</div>

	</div>

</template>

<script>

import { mapGetters } from 'vuex';
import planning_nav from '@/components/planning_nav'
import planning_page from '@/components/planning_page'
import site_service from '@/services/site_service'
import team_service from '@/services/team_service'

export default {
	name: 'site.planning',

	props: {
		modelValue: [Object],
		workspace: [Object],
		is_planning_complete: Boolean,
		is_design_complete: Boolean,
		is_build_complete: Boolean,
		is_launch_complete: Boolean
	},

	emits: [
		'update:modelValue',
	],

	components: {
		planning_nav,
		planning_page
	},

	data() {
		return {
			loading: false,
			saving: false,
			populating: false,
			site: null,
			success: null,
			error: {},
			tab: 'project',
			total: 15,
			team: null,
			socials: [
				{
					name: 'Facebook',
					key: 'facebook'
				},
				{
					name: 'Instagram',
					key: 'instagram'
				},
				{
					name: 'LinkedIn',
					key: 'linkedin'
				},
				{
					name: 'Pinterest',
					key: 'pinterest'
				},
				{
					name: 'TikTok',
					key: 'tiktok'
				},
				{
					name: 'Twitter / X',
					key: 'twitter'
				},
				{
					name: 'YouTube',
					key: 'youtube'
				},
			],
			delay_timer: null,
			steps: [
				{
					target: '.v-step-project',
					header: {
						title: 'Take a tour',
					},
					content: `Take a quick tour of the dashboard to gain a better understanding of where things are. If you're struggling with anything please don't hesitate to get in touch via live chat.`,
					params: {
						placement: 'left'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-share',
					header: {
						title: 'Share project',
					},
					content: `If you'd like to collaborate with your clients throughout the project, generate a share link and provide them with access to your client portal.`,
					params: {
						placement: 'left'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-board',
					header: {
						title: 'Project management board',
					},
					content: `Use the project management board to instantly see what tasks still need to be completed. The board is populated automatically as you move through the project.`,
					params: {
						placement: 'left'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-plan',
					header: {
						title: 'Plan',
					},
					content: `Create a comprehensive brief, organise pages to create the perfect sitemap and add your own content or let Bella write it for you.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-design',
					header: {
						title: 'Design',
					},
					content: `Once you're happy with your plan, convert content to bespoke designs with the help of Leo and creatively apply your clients branding.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-build',
					header: {
						title: 'Build',
					},
					content: `Once your designs are complete, let Pablo convert them into a fully functioning WordPress site ready for you to extend with custom functionality.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-launch',
					header: {
						title: 'Launch',
					},
					content: `Once your site is built, follow the simple step-by-step launch process to manage your SEO, analytics, GDPR and more.`,
					params: {
						placement: 'right'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-sub-nav',
					header: {
						title: 'Sub navigation',
					},
					content: `Each phase of the project has sub sections which you can easily navigate using this sub menu.`,
					params: {
						placement: 'bottom'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				/*{
					target: '.v-step-brief',
					header: {
						title: 'Manage brief',
					},
					content: `Create a comprehensive brief that covers who your client is, what they do and how they do it differently.`,
					params: {
						placement: 'bottom'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-sitemap',
					header: {
						title: 'Create sitemap',
					},
					content: `Add, edit and organise pages to create the perfect site structure.`,
					params: {
						placement: 'bottom'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},
				{
					target: '.v-step-content',
					header: {
						title: 'Add content',
					},
					content: `Write your own content or have Bella write it for you.`,
					params: {
						placement: 'bottom'
					},
					before: type => new Promise((resolve) => {
						this.handle_next(type, resolve)
					}),
				},*/
			]
		}
	},

	computed: {

		...mapGetters( 'user', ['auth_user']),

		...mapGetters( 'site', ['store_industries'] ),

	},

	mounted()
	{
		this.site = JSON.parse( JSON.stringify( this.modelValue ) )

		if ( this.site && this.site.settings && !this.site.settings['skip_wizard_complete'] && !this.site.settings['brief_complete'] && this.site.is_wizard ) {
			this.$router.push( { name: 'site.add', params: { site_id: this.site.id } } )
		} else if ( !this.site ) {
			this.$router.push( { name: 'site.add' } )
		}

		this.get_team()

		this.start_tour( 1 )
	},

	methods: {
		reset_error()
		{
			this.error = {}
		},

		scroll_to_section(event) 
		{
			event.preventDefault();
			const scrollContainer = document.getElementById('planning-pages');
			const targetId = event.target.getAttribute('href').substring(1);
			const targetElement = document.getElementById(targetId);
			scrollContainer.scrollTo({
				top: targetElement.offsetTop,
				behavior: 'smooth'
			});
		},

		highlight_link() 
		{
			const navLinks = document.querySelectorAll('.planning-page-link');

			let currentSection;
			document.querySelectorAll('.planning-page').forEach(section => {
				const rect = section.getBoundingClientRect();
				if (rect.top >= 0 && rect.top <= window.innerHeight / 2) {
					currentSection = section;
				}
			});

			navLinks.forEach(link => {
				link.classList.remove('active');
				if (currentSection && link.getAttribute('href').substring(1) === currentSection.id) {
					link.classList.add('active');
				}
			});
		},

		async get_team()
		{
			this.reset_error()

			team_service.show( this.auth_user.team_id ).then(( response ) => {
				this.team = response.data
			}).catch( ( error ) => {
				this.error = error
			})
		},

		submit_delay()
		{
			let vm = this
			clearTimeout( this.delay_timer );
			this.delay_timer = setTimeout(function() {
				vm.submit()
			}, 1000);
		},

		async submit()
		{
			this.saving = true 

			this.reset_error()

			this.site.question = this.tab

			site_service.update( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.saving = false

			})
		},

		async ai_populate()
		{
			this.loading = true 

			this.reset_error()

			site_service.ai_populate( this.$route.params.workspace_id, this.$route.params.site_id, this.site ).then(( response ) => {

				this.site = JSON.parse( JSON.stringify( response.data ) )

				this.success = 'Updated successfully'

				this.$emit('update:modelValue', this.site)

				window.$( '#success' ).modal( 'show' );

			}).catch( ( error ) => {

				this.error = error

			}).finally(() => {

				this.loading = false

			})
		},

		start_tour( count )
		{
			var vm = this
			setTimeout( function() {

				if ( localStorage.getItem('areoi-plan-tour') === null && count < 5 ) {
					if ( vm.$tours['build-tour'] ) {
						vm.$tours['build-tour'].start()
						localStorage.setItem('areoi-plan-tour', 'complete');
					} else {
						vm.start_tour( count + 1 );
					}
				}
			}, 500);
		},

		handle_next( type, resolve )
		{
			if ( ['previous', 'next'].includes( type ) ) {
				setTimeout( () => {
					resolve();
				}, 50);
			} else {
				resolve();
			}
		}
	}
}
</script>

<style>
	[data-popper-placement="right"] {
		margin-left: 10px !important;
	}
</style>